/** @format */

import React, { useEffect, useState } from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
// core components
import { useSelector } from "react-redux";
import "chart.js/auto";
import { CardWelcome } from "components/DashboardComponents/DashboardComponents";
import { CardRisorse } from "components/DashboardComponents/DashboardComponents";
import { CardLinkFacebook } from "components/DashboardComponents/DashboardComponents";
import { CardExpertAdvisor } from "components/DashboardComponents/DashboardComponents";
import { CardLinkTelegram } from "components/DashboardComponents/DashboardComponents";
import { CardDownloadMt4 } from "components/DashboardComponents/DashboardComponents";
import { CardRiepilogo } from "components/DashboardComponents/DashboardComponents";
import { InterestRateIFrame } from "components/DashboardComponents/DashboardComponents";
import Papa from "papaparse";
import { CardEsitiIrFilter } from "components/DashboardComponents/DashboardComponents";
import { CardLibro } from "components/DashboardComponents/DashboardComponents";
import { CardTradingRoom } from "components/DashboardComponents/DashboardComponents";
import { CardLibro2 } from "components/DashboardComponents/DashboardComponents";
import { CardLibro3 } from "components/DashboardComponents/DashboardComponents";
import { CardContoTopFx } from "components/DashboardComponents/DashboardComponents";
import { CardBestCurrency } from "components/DashboardComponents/DashboardComponents";
import { CardBestIndex } from "components/DashboardComponents/DashboardComponents";
import { CardBestCommodity } from "components/DashboardComponents/DashboardComponents";
import { CardRoibot } from "components/DashboardComponents/DashboardComponents";
import { responseSettings } from "fetchers/settings";
import { CardCorrelationFilter } from "components/DashboardComponents/DashboardComponents";
import { checkIsAdmin } from "variables/generalChecker";
import { checkPar } from "variables/generalChecker";
import { CardLinkPar } from "components/DashboardComponents/DashboardComponents";
import ChatGPTSection from "components/ChatGPT/ChatGPTSection";
import { responseGetUserClaim } from "fetchers/userClaims";
import { CardBoxDomande } from "components/DashboardComponents/DashboardComponents";
import { CardTicker } from "components/DashboardComponents/DashboardComponents";
import { CardAforismi } from "components/DashboardComponents/DashboardComponents";
import { CardSuperCalendario } from "components/DashboardComponents/DashboardComponents";
import { responseCalendarData } from "fetchers/calendarData";
import { CardProblemiTecnici } from "components/DashboardComponents/DashboardComponents";
import { CardLibroExcel } from "components/DashboardComponents/DashboardComponents";
import { checkLibroExcel } from "variables/generalChecker";
import { CardLibroExcelVideocorsi } from "components/DashboardComponents/DashboardComponents";
import { CardBestCurrencyH1 } from "components/DashboardComponents/DashboardComponentsH1";
import { CardBestIndexH1 } from "components/DashboardComponents/DashboardComponentsH1";
import { CardBestCommodityH1 } from "components/DashboardComponents/DashboardComponentsH1";
import { CardRiepilogoH1 } from "components/DashboardComponents/DashboardComponentsH1";
import { CardExpertAdvisorH1 } from "components/DashboardComponents/DashboardComponentsH1";
import { Switch } from "@mui/material";
import { okD1 } from "variables/activeTools";
import { okH1 } from "variables/activeTools";
import { CardPurpleTrading } from "components/DashboardComponents/DashboardComponents";
import { CardContoTeletrade } from "components/DashboardComponents/DashboardComponents";

function Dashboard(props) {
  const user = useSelector(state => state.user);
  const output = useSelector(state => state.output);
  const [modalOpen, setModalOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const [checkedd1h1, setCheckedD1H1] = React.useState(false); //false per d1
  useEffect(() => {
    async function getResponse() {
      const resSettings = await responseSettings();
      if (resSettings?.length > 0) {
        setSettings(resSettings);
      }
    }
    getResponse();
  }, []);
  useEffect(() => {
    if (okD1(output) && !okH1(output)) setCheckedD1H1(false);
    else if (!okD1(output) && okH1(output)) setCheckedD1H1(true);
  }, []);

  const [dataIR, setDataIR] = useState([]);
  useEffect(() => {
    const showsUrl =
      "https://docs.google.com/spreadsheets/d/15nfozHCgDwCD1h9KHS_PC0kPheZs9NPTmi0gLmkyjLY/pub?gid=0&single=true&output=csv";
    Papa.parse(showsUrl, {
      download: true,
      header: true,
      complete: results => {
        setDataIR(results.data);
      },
    });
    setModalOpen(true);
  }, []);

  const handleChange = event => {
    setCheckedD1H1(!checkedd1h1);
  };

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <>
          <div className="content">
            <Row>
              <Col lg="8" md="12">
                {settings && <CardTicker settings={settings} />}
                {user && <CardWelcome userInfo={user?.userInfo} />}
                <CardAforismi />
                {user && <CardRisorse risorse={user?.categories} />}
                {okD1(output) && okH1(output) && (
                  <Row>
                    <Col xs="12" className="items-center">
                      <p style={{ paddingTop: 13, fontWeight: "bold" }}>
                        GIORNALIERO
                      </p>
                      <Switch
                        checkedd1h1={checkedd1h1 ?? false}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p style={{ paddingTop: 13, fontWeight: "bold" }}>
                        ORARIO
                      </p>
                    </Col>
                  </Row>
                )}

                {/* D1 */}
                {!checkedd1h1 && (
                  <>
                    {settings && <CardBestCurrency settings={settings} />}
                    <CardBestIndex settings={settings} />
                    <CardBestCommodity settings={settings} />

                    {<ChatGPTSection output={output} d1H1="d1"/>}


                    {output && user && (
                      <CardRiepilogo output={output} userData={user} />
                    )}
                    {checkIsAdmin(user.userInfo.username) && (
                      <CardCorrelationFilter
                        outputStrumenti={output}
                        userData={user}
                      />
                    )}
                    {dataIR?.length > 0 && output?.strumenti.length > 0 ? (
                      <CardEsitiIrFilter dataIR={dataIR} output={output} />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {/* H1 */}
                {checkedd1h1 && (
                  <>
                    {settings && <CardBestCurrencyH1 settings={settings} />}
                    <CardBestIndexH1 settings={settings} />
                    <CardBestCommodityH1 settings={settings} />
                    {<ChatGPTSection output={output} d1H1="h1"/>}

                    {output && user && <CardRiepilogoH1 />}
                  </>
                )}
              </Col>

              {/* GENERAL */}
              <Col lg="4" md="12">
                {checkIsAdmin(user.userInfo.username) && (
                  <CardProblemiTecnici output={output.strumenti} />
                )}
                <CardBoxDomande user={user} />
                <InterestRateIFrame />

                {checkLibroExcel(user) && <CardLibroExcel />}
                {checkLibroExcel(user) && <CardLibroExcelVideocorsi />}

                {checkPar(user) && (
                  <CardLinkPar categories={user?.categories} />
                )}
                <CardLinkTelegram categories={user?.categories} />
                {user && <CardTradingRoom categories={user?.categories} />}
                {/*user && <CardLinkFacebook categories={user?.categories} />*/}
                {user && <CardRoibot categories={user?.categories} />}
                <CardLibro />
                <CardLibro2 />
                
                {user && <CardExpertAdvisor categories={user?.categories} />}
                {okH1(output) && <CardExpertAdvisorH1 />}
                <CardContoTeletrade />
                <CardDownloadMt4 />
              </Col>
            </Row>
          </div>
        </>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Dashboard;
