/**
 * /*!
 *
 * =========================================================
 * Black Dashboard React v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

/*eslint-disable*/
import SupportEmail from "components/Dialogs/SupportEmail";
import moment from "moment";
import React, { useState } from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <SupportEmail open={modalOpen} setModalOpen={setModalOpen} />
      <footer className="footer" style={{ backgroundColor: "black" }}>
        <Container fluid>
          <Nav>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.instagram.com/_mtinvestments_/")
                }
              >
                SEGUIMI SU IG
              </NavLink>
            </NavItem>
            <NavItem>{/* <NavLink>CONTATTACI</NavLink> */}</NavItem>
            {/* <NavItem>
              <NavLink onClick={toggleModal} style={{ cursor: "pointer" }}>
                Supporto tecnico
              </NavLink>
            </NavItem> */}
          </Nav>
          <div className="copyright">
            {moment().format("LLLL") + "     "}© Manuel Trotta
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
