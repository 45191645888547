/**
 * /*!
 *
 * =========================================================
 * Black Dashboard React v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import moment from "moment/moment";
import "moment/locale/it";
// reactstrap components
import {
  Badge,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalHeader,
  Nav,
  Navbar,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginEmpty } from "../../actions/login/actionsLogin";
import { ThemeContext, themes } from "contexts/ThemeContext";
import { studies } from "contexts/StatisticsContext";
import { responseRevoke } from "fetchers/revoke";
import { getCookie } from "cookieCreator";
import { activeUsers } from "variables/generalChecker";
import { checkIsAdmin } from "variables/generalChecker";

function AdminNavbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapseOpen, setcollapseOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalSearch, setmodalSearch] = useState(false);
  const [onlineUser, setOnlineUser] = useState(0);
  const user = useSelector(state => state.user);

  const [color, setColor] = useState("navbar-transparent");

  useEffect(() => {
    let currentHour = parseInt(moment().format("LT").split(":"));
    let usersNumber = activeUsers(currentHour);
    setOnlineUser(usersNumber);
  }, []);
  useEffect(() => {
    let currentHour = parseInt(moment().format("LT").split(":"));
    let usersNumber = activeUsers(currentHour);
    const interval = setInterval(() => setOnlineUser(usersNumber), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [onlineUser]);
  useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const logout = () => {
    responseRevoke(getCookie("token"));
    setLoginEmpty();
    navigate("/login");
  };

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-light");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
    <ThemeContext.Consumer>
      {({ changeTheme, theme }) => (
        <>
          <Navbar className={classNames("navbar-absolute", color)} expand="lg">
            <Container fluid>
              <div
                className="navbar-wrapper"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: props.sidebarOpened,
                  })}
                >
                  <NavbarToggler onClick={props.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </NavbarToggler>
                </div>

                {window.outerWidth < 991 ? (
                  <img
                    alt="..."
                    style={{
                      //height: 70,
                      width: "60%",
                    }}
                    src={
                      (window.innerWidth <= 768 && dropdownOpen && !theme) ||
                      (window.innerWidth > 768 && theme) ||
                      (window.innerWidth <= 768 && theme)
                          ? require("assets/img/MTplatform_marchio_bianco.png")
                           : require("assets/img/MTplatform_marchio_bianco.png")
                          //require("assets/img/logo_lungo_450_225.png")
                        //require("assets/img/logo_lungo_450_225.png")
                    }
                  />
                ) : (
                  <img
                    alt="..."
                    style={{
                      width: 210,
                      position: "fixed",
                      top: 35,
                    }}
                    src={
                      (window.innerWidth <= 768 && dropdownOpen && !theme) ||
                      (window.innerWidth > 768 && theme) ||
                      (window.innerWidth <= 768 && theme)
                            ? require("assets/img/MTplatform_marchio_bianco.png")
                           : require("assets/img/MTplatform_marchio_bianco.png")
                          //require("assets/img/logo_lungo_450_225.png")
                        //require("assets/img/logo_lungo_450_225.png")
                    }
                  />
                )}

                <NavbarToggler onClick={toggleCollapse}>
                  <span
                    onClick={() => {
                      setDropdownOpen(!dropdownOpen);
                    }}
                    className="navbar-toggler-bar navbar-kebab"
                  />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
              </div>

              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                  {/* {checkIsAdmin(user.userInfo.username) ? (
                    <Badge
                      tag="h1"
                      color="danger"
                      className="mt-3 mr-4"
                      onClick={() => {
                        props.currentStat === "daily"
                          ? props.handleStatClick(studies.h1)
                          : props.handleStatClick(studies.d1);
                      }}
                      style={{ fontSize: 12, cursor: "pointer" }}
                    >
                      {props.currentStat === "daily" ? "D1" : "H1"}
                    </Badge>
                  ) : (
                    <></>
                  )} */}
                  <Badge
                    tag="h1"
                    color="info"
                    className="mt-3 mr-4"
                    style={{ fontSize: 12 }}
                    onClick={() => console.log(props.handleStatClick)}
                  >
                    Utenti online: {onlineUser}
                  </Badge>

                  <div style={{ display: "flex" }}>
                    <DarkModeSwitch
                      style={{ marginTop: window.innerWidth <= 768 ? 15 : 9 }}
                      size={25}
                      checked={theme === "" ? true : false}
                      onChange={() => {
                        theme === "white-content"
                          ? changeTheme(themes.dark)
                          : changeTheme(themes.light);
                      }}
                    />
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        nav
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <i className="fa-solid fa-user mt-1"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <DropdownItem divider tag="li" />
                        <NavLink tag="li">
                          <DropdownItem className="nav-item" onClick={logout}>
                            Log out
                          </DropdownItem>
                        </NavLink>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
          <Modal
            modalClassName="modal-search"
            isOpen={modalSearch}
            toggle={toggleModalSearch}
          >
            <ModalHeader>
              <Input placeholder="SEARCH" type="text" />
              <button
                aria-label="Close"
                className="close"
                onClick={toggleModalSearch}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
            </ModalHeader>
          </Modal>
        </>
      )}
    </ThemeContext.Consumer>
  );
}

export default AdminNavbar;
