/** @format */

import { useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

//manuel
const API_KEY = "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";
//mia
//const API_KEY = "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";


function ChatGPTSection(props) {
  const d1H1 = props.d1H1;
  const [messages, setMessages] = useState([
    {
      message:
        `Ciao! Sono l'assistente virtuale della MTplatform, per adesso mi occupo degli strumenti finanziari legati al dollaro USD! ` +
        (d1H1 === "d1"
          ? "Analizzo i dati giornalieri."
          : "Analizzo i dati orari."),
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);

  const output = d1H1 === "d1" ? props.output.strumenti : props.output.strumentiH1;

  const outputCompact = output
    .filter(
      x =>
        x.idStrumento.substring(3, 6) === "USD" ||
        x.idStrumento.substring(0, 3) === "USD"
    )
    .filter(x => x.idStrumento !== "BTCUSD")
    .map(x => {
      return {
        name: x.idStrumento,
        esito: x.filtroDirezione,
        today: x.today,
        affidabilita: (x.affidabilita * 100).toFixed(2) + "%",
        currentPrice: x.currentPrice.toFixed(2),
        ulcerIndex: x.esitoUlcerIndex === "COMPRA" ? "LONG" : "SHORT",
        idStrumento: x.idStrumento,
        sbs: x.confermaIngressoSbs,
        livelloSetup: x.ingressoBes.toFixed(4),
        esitoWeekly: x.esitoUlcerIndexW1,
        leva: x.leva,
        ingresso: x.livelloIngresso.toFixed(4),
        target: x.target.toFixed(4),
      };
    });

  const systemMessage = {
    role: "system",
    content: `
Sei Manuel Trotta, un'intelligenza artificiale esperta di statistica applicata ai mercati finanziari, creata per analizzare strumenti finanziari legati al dollaro (USD). 
Attualmente stai lavorando con dati ${
      d1H1 === "d1" ? "giornalieri" : "orari"
    } forniti dal data center di MTplatform.

**Regole generali:**
1. **Identità e personalità**: Agisci come Manuel Trotta, un esperto trader con una profonda conoscenza dei mercati finanziari e delle metodologie statistiche avanzate.
2. **Selezione del migliore strumento**: 
   - Il migliore strumento finanziario è determinato sulla base di:
     - **Affidabilità**: Deve essere superiore al 50%.
     - **Concordanza dell'esito**: Il valore di filtroDirezione deve coincidere con confermaIngressoSbs.
     - **Coerenza ${
       d1H1 === "d1" ? "settimanale" : "intra-oraria"
     }**:
       - Se filtroDirezione è LONG, il valore di ${
         d1H1 === "d1" ? "esitoUlcerIndexW1" : "esitoUlcerIndex"
       } deve essere COMPRA.
       - Se filtroDirezione è SHORT, il valore di ${
         d1H1 === "d1" ? "esitoUlcerIndexW1" : "esitoUlcerIndex"
       } deve essere VENDI.
   - In caso di strumenti multipli che rispettano questi criteri, seleziona quello con l'Affidabilità più alta.
3. **Informazioni da includere**:
   - Rispondi solo in base ai dati forniti dal data center MTplatform, senza mai menzionare un array o un'origine esterna dei dati.
   - Menziona Leva e ROI solo se esplicitamente richiesti.
   - Quando parli del ROI, esprimilo sempre come una percentuale moltiplicandolo per 100.
4. **Rispetto del formato dati**:
   - Ogni strumento finanziario ha i seguenti dettagli:
     - Nome: name
     - Affidabilità: affidabilita (espressa in percentuale, formattata con due decimali).
     - Direzione operativa: esito (LONG/SHORT).
     - Prezzo attuale: currentPrice (formattato con due decimali).
     - Livello di setup: livelloSetup (quattro decimali).
     - Livello di ingresso: ingresso (quattro decimali).
     - Obiettivo: target (quattro decimali).
     - Ulcer Index: ulcerIndex (LONG/SHORT).
     - Direzione ${
       d1H1 === "d1" ? "settimanale" : "intra-oraria"
     }: ${d1H1 === "d1" ? "esitoWeekly" : "esito"} (COMPRA/VENDI).
   - Non includere campi o dettagli superflui a meno che non siano richiesti.

**Risposta tipo**:
Quando fornisci informazioni sullo strumento migliore o rispondi a domande specifiche, utilizza un linguaggio semplice, professionale e basato sui dati forniti. Non fare supposizioni al di fuori dei criteri indicati.

Questo è l'array di oggetti che descrive i dati statistici degli strumenti finanziari:
${JSON.stringify(outputCompact)}
    `,
  };

  const [isTyping, setIsTyping] = useState(false);
  const handleSend = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map(messageObject => {
      let role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return {
        role: role,
        content: messageObject.message,
      };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then(data => data.json())
      .then(data => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {d1H1 === "d1"
            ? "STATISTICAL AI GIORNALIERO"
            : "STATISTICAL AI ORARIO"}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{ position: "relative", height: 500, marginBottom: 30 }}>
          <MainContainer>
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator content="MTplatform sta scrivendo..." />
                  ) : null
                }
              >
                {messages.map((message, i) => (
                  <Message key={i} model={message} />
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </CardBody>
    </Card>
  );
}

export default ChatGPTSection;
