/** @format */

import { Form, Input } from "antd";
import jwt_decode from "jwt-decode";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Input as InputStrap,
} from "reactstrap";
import Loader from "components/Loader/Loader";
import { getCookie } from "cookieCreator";
import { responseLogin } from "fetchers/login";
import { cookieCreator } from "cookieCreator";
import { ThemeContext } from "contexts/ThemeContext";

const Login = () => {
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [token, setToken] = useState(getCookie(""));

  const onFinish = values => {
    setSubmit(true);
    const response = responseLogin({
      username: values.username,
      password: values.password,
    }).then(response => validateLogin(response));
  };

  const validateLogin = response => {
    if (response.ok) setToken(response.token);
    else {
      alert("EMAIL O PASSWORD NON CORRETTI");
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (token) {
      if (Date.now() < jwt_decode(token).exp * 1000) navigate("/admin");
      else {
        cookieCreator("token", "");
        cookieCreator("refreshToken", "");
        setToken("");
      }
    }
  }, [token]);

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#0C1157", //BACKGROUND!!!
          }}
        >
          <Card
            className="card-user"
            style={{
              height: "100vh",
            }}
          >
            <CardBody>
              <CardText />
              <div
                style={{
                  display: "flex",
                  flexDirection: " column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <div className="photo">
                    <img
                      alt="..."
                      style={{ width: 300, height: 110 }}
                      src={
                        theme
                           ? require("../../assets/img/MTplatform_marchio.png")
                          : require("../../assets/img/MTplatform_marchio_bianco.png")
                           // require("../../assets/img/logo_lungo_450_225.png")
                           // require("../../assets/img/logo_lungo_450_225.png")
                      }
                    />
                  </div>
                  {/* <h5 className="title">Manuel ™ WebApp</h5> */}
                  <p className="description"></p>
                </div>
                {submit ? (
                  <div style={{ marginTop: 110 }}>
                    <Loader />
                  </div>
                ) : (
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    style={{
                      height: 200,
                      alignItems: "space-around",
                    }}
                    autoComplete="on"
                  >
                    <Form.Item
                      name="username"
                      rules={[
                        { required: true, message: "Inserisci Username!" },
                      ]}
                    >
                      <Input
                        style={{ marginBottom: 10 }}
                        prefix={
                          <UserOutlined
                            className="site-form-item-icon"
                            style={{ marginRight: 10 }}
                          />
                        }
                        placeholder="Username"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Inserisci Password!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <LockOutlined
                            style={{ marginRight: 10 }}
                            className="site-form-item-icon"
                          />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>

                    {/* <Form.Item>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        style={{
                          marginTop: 10,
                          display: "flex",
                        }}
                      >
                        <Checkbox
                      style={{
                        backgroundColor: "red",
                        width: 120,
                      }}
                    >
                      Remember me
                    </Checkbox>
                      </Form.Item>
                    </Form.Item> */}

                    <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmltype="submit"
                        className="login-form-button"
                      >
                        Log in
                      </Button>
                    </Form.Item>

                    <a
                      href="https://www.tradingstatistico.it/area-riservata/login?sendpass"
                      target="_blank"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>Password dimenticata?</p>
                    </a>
                  </Form>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="button-container">
                <Button
                  className="btn-icon btn-round"
                  color="instagram"
                  onClick={() =>
                    window.open("https://www.instagram.com/_mtinvestments_/")
                  }
                >
                  <i className="fab fa-instagram" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="titok"
                  onClick={() =>
                    window.open("https://www.tiktok.com/@mtinvestments?_t=8rJA22DWVhW&_r=1")
                  }
                >
                  <i className="fab fa-tiktok" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="youtube"
                  onClick={() =>
                    window.open(
                      "https://youtube.com/@mtinvestmentsacademy?si=F4g_qm6fYn6vHfDL"
                    )
                  }
                >
                  <i className="fab fa-youtube" />
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default Login;
